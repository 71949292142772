<template>
  <div class="train train-img-w" >
    <MyBreadcrumb></MyBreadcrumb>
    <div class="train-cont">
      <div class="left">
        <div class="left-top" >
          <div v-if="!loading">
            <div class="tit">
              <span>{{$t('trainingcamp_train_second')}}{{curIndex + 1}}{{$t('trainingcamp_train_topic')}}</span>
              <span>{{ typesObj[answerCardList[curIndex].type]}}{{$t('trainingcamp_train_topic')}}</span>
              <span v-if="answerCardList[curIndex].type != 1 && answerCardList[curIndex].type != 2 && answerCardList[curIndex].type != 3">({{$t('trainingcamp_train_no_answer')}})</span>
            </div>
            <div class="name" v-html="answerCardList[curIndex].title" style="display: inline-block;">
              <!--          {{ dataInfo.datasetData.name }}-->
            </div>
            <div class="cont" v-if="answerCardList[curIndex].type == 1 || answerCardList[curIndex].type == 2 || answerCardList[curIndex].type == 3">
              <div  class="mb10" v-for="(item,index) in answerCardList[curIndex].options" :key="item.id">
                <p style="display: flex;justify-content: flex-start"><span>{{optionList[index]}}:</span><span v-html="item.name" style="display: inline-block;"></span></p>
              </div>
            </div>
            <div class="bot">
              <div class="btn">
                <Button @click="pre">{{$t('trainingcamp_train_pre')}}</Button>
                <Button @click="next">{{$t('trainingcamp_train_next')}}</Button>

              </div>
            </div>
          </div>
          <Spin fix v-else></Spin>
        </div>
      </div>
      <div class="right">
        <div class="right-top" >
          <div class="tit">
            <div>
              {{ $t('trainingcamp_train_answer_sheet') }}
            </div>
          </div>

          <div class="answer-card">
            <div v-if="!loading" class="list">
              <div :class="{active:curIndex == index} " v-for="(item,index) in answerCardList" :key="item.id" @click="changeAnswerCard(index)">
                <span>{{index + 1}}</span>
              </div>
            </div>
            <Spin fix v-else></Spin>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import Richtxt from '@/components/production/richtxt.vue';

export default {
  name: "train.vue",
  data(){
    return {
      answerCardList:[],
      loading:true,
      curData:{}, //当前选中数据id
      curIndex:0,
      dataInfo:{},  //数据详情
      infoLoading:true,
      typesObj:{},
      optionList:['A','B','C','D','E','F','G','H','I','J','K','L'],
      examineId:'',
    }
  },
  components:{
    Richtxt,
  },
  created(){
    this.examineId = this.$route.query.id || '';
    this.getList();
  },
  mounted(){
  },
  methods:{
    getList(){
      let params = {
        id:this.examineId
      }
      this.loading = true;
      this.api.course.examinePreviewDataList(params).then((res)=>{
        console.log('121212============')
        this.loading = false;
        this.answerCardList = res.list;
        this.typesObj = res.data_types;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeAnswerCard(index){
      this.curIndex = index;
    },
    pre(){
      if(this.curIndex > 0){
        this.curIndex --;
      }else{
        this.$Message.warning('已经是第一题')
      }
    },
    next(){
      if(this.curIndex < this.answerCardList.length - 1){
        this.curIndex ++;
      }else{
        this.$Message.warning('已经是最后一题')
      }
    }
  },


}
</script>

<style scoped lang="scss">
.train{
  padding: 20px;
  font-size: 14px;

  .train-cont{
    display: flex;
    justify-content: space-between;
  }

  .left{
    flex: 1;
    margin-right: 20px;
    .left-top{
      padding:20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;
      position: relative;

      .tit{

        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        >span:nth-child(2){
          margin-left: 20px;
          font-size: 16px;
          font-weight: normal;
        }
        >span:nth-child(3){
          margin-left: 5px;
          font-size: 12px;
          font-weight: normal;
        }
      }
      .name{
        margin:10px 0 ;
      }
      .cont{
        .option{
          margin-bottom: 10px;
        }
      }
      .bot{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn{
          button{
            margin-right: 10px;
          }
        }

        .op{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          >div{
            margin-left: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            >img{
              margin-right: 5px;
              width: 20px;
              height: 20px;
            }
          }

        }
      }
    }
    .analysis{
      margin-top: 20px;
      padding:20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;

      .answer{
        margin-bottom: 10px;

        .blue{
          color: #2d8cf0;
        }
        .green{
          color: #19be6b;
        }
        .red{
          color: #ed4014;
        }
      }
      .cont{
        //font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        >span:nth-child(1){
          width: 50px;
        }
        >span:nth-child(2){
          flex: 1;
          width: 0;
          word-break: break-all;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }
    .check-similar-data{
      margin-top: 20px;
      display: inline-block;
      padding: 0 14px;
      height: 30px;
      background: #4A79FF;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      cursor: pointer;
    }
  }
  .right{
    width: 400px;
    .right-top{
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;

      .tit{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #dcdee2;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .again{
        white-space:normal
      }
      .answer-card{
        margin: 20px 10px;

        height: 380px;
        overflow-y: auto;
        position: relative;
        .list{
          padding-top: 4px;
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          >div{
            width: 50px;
            height: 30px;
            border: 1px solid #dcdee2;
            text-align: center;
            line-height: 30px;
            border-radius: 4px;
            margin: 0 10px 10px 10px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            >img{
              position: absolute;
              top:-4px;
              right: -7px;
            }
          }

          >div.error{
            background-color: #ec808d;
            color:#FFFFFF;
          }
          >div.corret,
          >div.complete{
            background-color: #19be6b;
            color:#FFFFFF;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            //border: 1px solid #2d8cf0;
          }
          >div.error.active{
            background-color:red;
            border: none;
          }
          >div.corret.active,
          >div.complete.active{
            background-color: green;
            border: none;
          }
          >div.mark{
            border: 1px solid #FF4D4F;

          }
        }
      }

    }
    .submit-papers{
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      margin-bottom: 20px;
      padding: 20px;
      text-align: center;

      .submit-btn{
        margin: 0 auto 15px auto;
        width: 100px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border:1px solid #19be6b;
        border-radius: 4px;
        cursor: pointer;
      }
      .submit-time{
        font-size: 30px;
        font-weight: bold;
        color:#ed4014;
      }
    }
    .exam-result{
      margin-bottom: 20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;

      .exam-result-tit{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #dcdee2;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .exam-result-cont{
        margin: 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .exam-result-cont-l{
          .exam-result-cont-l-item{
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            >p:nth-child(1){
              width: 65px;
              text-align: right;
              margin-right: 10px;
            }
            >p:nth-child(2){
              flex: 1;
              >span{
                color:#ed4014;
                font-size: 16px;
                font-weight: bold;
              }
            }
          }
        }
        .exam-result-cont-r{
          font-size: 20px;
          font-weight: bold;
        }
        .exam-result-cont-r.success{
          color:#19be6b;
        }
        .exam-result-cont-r.fail{
          color:#ed4014;
        }
      }
      .num{
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        span{
          padding-left:5px;
          padding-right: 2px;
          font-weight: bold;
        }

        .blue{
          color:#19be6b;
        }
        .red{
          color:#ed4014;
        }
      }
    }
  }
  .option{
    font-size: 16px;
    //white-space: -moz-pre-wrap;
    white-space:normal;
    height: auto;
  }
  .checkoutOption{
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    white-space:normal;
    height: auto;
  }
}
.bjColor{
  background-color: #dee6de;
}
.nrBjColor{
  background-color: #f0faef !important;
}
.big-font-size{
  font-size: 18px !important;
}
.little-font-size{
  font-size: 14px!important;
}
.middle-font-size{
  font-size: 16px!important;
}
.correction{  //纠错
  height: 500px;
  overflow-y: auto;
  .type{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .tit,
  .option-item,
  .analysis{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .left{
      width: 100px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;


      .correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .right{
      margin-left: 20px;
      flex: 1;
      border: 1px solid #dcdee2;

      .right-list{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div{
          margin-right: 10px;
          border: 1px solid #dcdee2;
          border-radius: 4px;
          padding: 5px 10px;
          text-align: center;
          cursor: pointer;
        }
        .active{
          border: 1px solid #2d8cf0;
          background-color: #2d8cf0;
          color:#FFFFFF;
        }
      }
    }
  }
  .analysis{
    margin-top: 20px;
  }

}
.confirm-submit-txt{
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
}
.attention{
  .user-info{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    >div{
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        width: 40px;
      }
    }
  }
  .attention-tit{
    margin: 10px auto;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
  .attention-cont{
    font-size: 14px;
    height: 400px;
    overflow-y: auto;
    line-height: 25px;
    >p{
      margin-bottom: 10px;
    }
  }
}
.cont-input{
  margin-bottom: 20px;
}
.next-btn{
  margin-top: 20px;
  display: inline-block;
  padding: 0 14px;
  height: 30px;
  background: #4A79FF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
  cursor: pointer;
}
</style>
<style>
.train .ivu-checkbox-disabled+span{
  color:#515a6e !important;
}
/*.train-img-w img{*/
/*  width: 100%;*/
/*}*/
</style>
